import React, { useState } from "react";
import { useForm } from "react-hook-form";

const ContactForm = ({ data }) => {
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting }
  } = useForm();

  const onSubmit = async data => {
    await fetch(process.env.GATSBY_API_URL + 'messages', {
      method: "POST",
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.GATSBY_API_KEY,
        "api-secret-key": process.env.GATSBY_API_SECRET_KEY,
        "form-uuid": process.env.GATSBY_FROM_UUID,
        "language-code": "fr_FR",
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(result => {
      if(result.error === false){
        setSubmitted(true);
        reset();
      }else if(result.error === true){
        console.log(result);      
        setError(
          "submit",
          "submitError",
        );
      }
    })
    .catch(error => {
      console.log(error);      
      setError(
        "submit",
        "submitError",
        `Oups! Il semble y avoir un problème. ${error.message}`
      );  
    });
  };

  const showSubmitError = msg => <p className="msg-error">Oups! Il semble y avoir un problème.</p>;

  const showThankYou = (
    <div className="msg-confirm">
      <p>Votre message a été envoyé</p>
      <button type="button" className="btn btn-sm btn-primary text-white" onClick={() => setSubmitted(false)} aria-hidden="true">
          Souhaitez-vous envoyer un autre message?
      </button>
    </div>
  );

  const showForm = (
    <form onSubmit={handleSubmit(onSubmit)} method="post">

      <div className="row">
        <div className="col-md-6 form-group">
          <label htmlFor="first_name">Prénom</label>
          <input
            type="text"
            name="first_name"
            id="first_name"
            className="form-control"
            placeholder="Votre prénom"
            maxLength="100"
            {...register("first_name", { required: true })}
            disabled={isSubmitting}
          />
          {errors.first_name && <div className="msg-error">Ce champ est obligatoire</div>}
        </div>

        <div className="col-md-6 form-group">
          <label htmlFor="last_name">Nom</label>
          <input
            type="text"
            name="last_name"
            id="last_name"
            className="form-control"
            placeholder="Votre nom"
            maxLength="100"
            {...register("last_name", { required: true })}
            disabled={isSubmitting}
          />
          {errors.last_name && <div className="msg-error">Ce champ est obligatoire</div>}
        </div>
      </div>
      <div className="form-group mt-1">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          id="email"
          className="form-control"
          placeholder="Votre email"
          maxLength="255"
          {...register("email", { required: true })}
          disabled={isSubmitting}
        />
        {errors.email && <div className="msg-error">Ce champ est obligatoire</div>}
      </div>
      <div className="form-group mt-1">
          <label htmlFor="telephone">Téléphone</label>
          <input
            type="text"
            name="telephone"
            id="telephone"
            className="form-control"
            placeholder="Votre téléphone"
            maxLength="20"
            {...register("telephone", { required: true })}
            disabled={isSubmitting}
          />
          {errors.telephone && <div className="msg-error">Ce champ est obligatoire</div>}
      </div>
      <div className="form-group mt-1">
        <label htmlFor="subject">Objet</label>
        <input
          type="text"
          name="subject"
          id="subject"
          className="form-control"
          placeholder="Objet"
          maxLength="100"
          {...register("subject", { required: true })}
          disabled={isSubmitting}
        />
        {errors.subject && <div className="msg-error">Ce champ est obligatoire</div>}
      </div>
      <div className="form-group mt-1">
        <label htmlFor="question">Message</label>
        <textarea
          name="message"
          id="message"
          rows="5"
          className="form-control"
          placeholder="Votre message"
          maxLength="1000"
          {...register("message", { required: true })}
          disabled={isSubmitting}
        ></textarea>
        {errors.message && (
          <div className="msg-error">Ce champ est obligatoire</div>
        )}
      </div>
      <div className="text-center mt-3">
        <button type="submit" className="btn btn-lg btn-primary text-white" disabled={isSubmitting}>
          Envoyer
        </button>
      </div>
    </form>
  );

  return (
    <div className="email-form shadow">
      <div>{submitted ? showThankYou : showForm}</div>
      <div className="text-center my-3">
        {errors && errors.submit && showSubmitError(errors.submit.message)}
      </div>
    </div>
  );
};

export default ContactForm