import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

// Components
import Layout from '../components/layout/Layout'
import ContactForm from '../components/form/Contact'

const ContactPage = () => {

  const query = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          title
          description
          author
          addressStreet
          addressPostalCode
          addressCity
          addressCountry
          addressGoogleDirection
          email
          telephone
          telephoneIndex
          schedules
        }
      }
    }
  `)

  return (
    <Layout pageTitle="Contact" headerClassPosition="sticky-top">
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Contactez nous</h2>
            <ol>
              <li key="accueil">
                <Link to={`/`} className="text-reset">Accueil</Link>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section className="contact">
        <div className="container">
          <div className="section-title">
            <h2>Contactez nous</h2>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="info-box shadow mb-4">
                      {
                        query.site.siteMetadata.title
                        ? 
                        <h3>{query.site.siteMetadata.title}</h3>
                        : null
                      } 
                      <p>
                        <div>
                            {
                              query.site.siteMetadata.addressStreet
                              ? 
                              <a href={query.site.siteMetadata.addressGoogleDirection} className="text-reset" target="_blank" rel="noreferrer">
                                <div><i className="bi bi-geo-alt"></i> {query.site.siteMetadata.addressStreet}</div>
                              </a>
                              : null
                            }
                            <a href={query.site.siteMetadata.addressGoogleDirection} className="text-reset" target="_blank" rel="noreferrer">
                              {
                                query.site.siteMetadata.addressPostalCode
                                ? 
                                <span>{query.site.siteMetadata.addressPostalCode} </span>
                                : null
                              }
                              {
                                query.site.siteMetadata.addressCity
                                ? 
                                <span>{query.site.siteMetadata.addressCity} </span>
                                : null
                              }
                              {
                                query.site.siteMetadata.addressCountry
                                ? 
                                <span>{query.site.siteMetadata.addressCountry}</span>
                                : null
                              }
                            </a>
                        </div>
                        {
                          query.site.siteMetadata.telephone
                          ? 
                          <div><i className="bi bi-telephone"></i> <a href={"tel:" + query.site.siteMetadata.telephoneIndex} className="text-reset">{query.site.siteMetadata.telephone}</a></div>
                          : null
                        } 
                        {query.site.siteMetadata.schedules.map((schedule, index) => (
                          <p key={index}>{schedule}</p>
                        ))}
                      </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="info-box shadow mb-4 p-0">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2771.484629941365!2d4.047121682625692!3d46.00151845777417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f4055d10194f01%3A0xead6b4548c48a186!2sAtrier+Roannais!5e0!3m2!1sfr!2sfr!4v1442921228552"
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      allowFullScreen=""
                      aria-hidden="false"
                      title={query.site.siteMetadata.title}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage
